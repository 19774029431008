import React from 'react';
import {t} from 'i18next';

import {AboutText, ThemeProvider, Text} from '@pexip/components';
import {SettingsPanel, PanelHeader} from '@pexip/media-components';

import {APP_VERSION, VERSION, DIRTY, PLUGIN_API_VERSION} from '../constants';

export const About: React.FC<{onBackClick: () => void}> = ({onBackClick}) => {
    return (
        <SettingsPanel
            headerContent={
                <ThemeProvider colorScheme="light">
                    <PanelHeader
                        title={`${t('settings.about', 'About')}`}
                        onBackClick={onBackClick}
                    />
                </ThemeProvider>
            }
        >
            <ThemeProvider colorScheme="light">
                <AboutText
                    className=""
                    headingText=""
                    spacing="none"
                    version={`${APP_VERSION}+${VERSION}${DIRTY}`}
                    enhancerEnd={
                        <Text>Plugin API Version: {PLUGIN_API_VERSION}</Text>
                    }
                />
            </ThemeProvider>
        </SettingsPanel>
    );
};

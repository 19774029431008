import type {SyntheticEvent} from 'react';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {IconTypes, type MenuContent} from '@pexip/components';

import {rootLogger} from '../logger';
import {TestId} from '../../test/testIds';
import {useSettingsMenuActions} from '../plugins/hooks/useSettingsMenuAction';
import {useInfinityContext} from '../hooks/useInfinityContext';
import type {UserMenuStep} from '../types';

import {useMe} from './useMe';
import {useFeedback} from './useFeedback';

const downloadLogWithName = () => rootLogger.downloadLog();

export const useUserMenuContent = ({
    isInMeeting,
    setUserMenuStep,
    hasMedia,
    close,
}: {
    isInMeeting: boolean;
    setUserMenuStep: (settings: UserMenuStep) => void;
    hasMedia?: boolean;
    close: (e: React.SyntheticEvent<HTMLElement>) => void;
}) => {
    const {t} = useTranslation();
    const infinity = useInfinityContext();
    const me = useMe(infinity.getMe);
    const meeting = infinity.getMeeting();
    const isDirectMedia = meeting.getConferenceFeatureFlags()?.isDirectMedia;
    const canControl = me?.canControl;

    const {isFeedbackEnabled, openFeedbackForm} = useFeedback();

    const pluginActions = useSettingsMenuActions(me, isInMeeting);
    const menuContent: MenuContent = useMemo(() => {
        const menu = [
            ...(hasMedia
                ? [
                      {
                          label: t(
                              'settings.video-and-sound',
                              'Video and Sound',
                          ),
                          clickAction: () => setUserMenuStep('video-and-sound'),
                          testId: TestId.ButtonMeetingDeviceSettings,

                          role: 'menuitem',
                      },
                  ]
                : []),
            {
                label: t('settings.quality', 'Quality'),
                clickAction: () => setUserMenuStep('quality'),
                testId: TestId.LinkQuality,

                role: 'menuitem',
            },
            ...(!isDirectMedia && isInMeeting && canControl
                ? [
                      {
                          label: t(
                              'settings.meeting-settings',
                              'Meeting Settings',
                          ),
                          clickAction: () =>
                              setUserMenuStep('meeting-settings'),
                          testId: TestId.TabSettingsMeetingSettings,

                          role: 'menuitem',
                      },
                  ]
                : []),
            ...(isInMeeting &&
            Boolean(me?.canChangeLayout) &&
            !meeting.getConferenceFeatureFlags()?.isDirectMedia
                ? [
                      {
                          label: t('settings.meeting-layout', 'Meeting layout'),
                          clickAction: () => setUserMenuStep('layouts'),
                          testId: TestId.TabSettingsMeetingLayout,
                          role: 'menuitem',
                      },
                  ]
                : []),
            {
                label: t('settings.additional', 'Additional settings'),
                clickAction: () => setUserMenuStep('additional-settings'),
                testId: TestId.TabSettingsAdditionalSettings,
                role: 'menuitem',
            },
            ...(isInMeeting
                ? [
                      {
                          label: t('stats.media-stats', 'Media statistics'),
                          clickAction: () => setUserMenuStep('stats'),
                          testId: TestId.ButtonSelfviewQuality,

                          role: 'menuitem',
                      },
                  ]
                : []),
            {
                label: t('settings.languages', 'Languages'),
                clickAction: () => setUserMenuStep('language'),
                testId: TestId.TabSettingsAdditionalSettings,
                role: 'menuitem',
            },
            ...pluginActions,
            {
                label: t('common.download-log', 'Download log'),
                testId: TestId.LinkDownloadLog,
                clickAction: downloadLogWithName,
                role: 'menuitem',
            },
            {
                label: t('settings.about', 'About'),
                clickAction: () => setUserMenuStep('about'),
                testId: TestId.LinkAboutPexip,

                role: 'menuitem',
            },
            ...(isFeedbackEnabled
                ? [
                      {
                          label: t('meeting.menu-feedback', 'Send feedback'),
                          testId: TestId.SubmitFeedback,
                          clickAction: (
                              e: React.SyntheticEvent<HTMLElement>,
                          ) => {
                              openFeedbackForm();
                              close(e);
                          },
                          icon: IconTypes.IconOpenInNew,
                          role: 'menuitem',
                      },
                  ]
                : []),
        ];

        return [menu];
    }, [
        hasMedia,
        t,
        isDirectMedia,
        isInMeeting,
        canControl,
        me?.canChangeLayout,
        meeting,
        pluginActions,
        isFeedbackEnabled,
        setUserMenuStep,
        openFeedbackForm,
        close,
    ]);

    return {
        menuContent,
    };
};

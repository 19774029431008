import type {SyntheticEvent} from 'react';
import React from 'react';

import {Menu} from '@pexip/components';

import {ConfirmationModalStates} from '../types';
import type {UserMenuStep} from '../types';
import {useUserMenuContent} from '../hooks/useUserMenuContent';
import {TestId} from '../../test/testIds';

import {VideoAndSound} from './VideoAndSound.viewModel';
import {About} from './About.viewModel';
import {Quality} from './Quality.viewModel';
import {Layouts} from './Layouts.viewModel';
import {InMeetingSettings} from './InMeetingSettings.viewModel';
import {NerdStats} from './NerdStats.viewModel';
import {AdditionalSettings} from './AdditionalSettings.viewModel';
import {Languages} from './Languages.viewModel';

export const UserMenuHandler: React.FC<{
    isInMeeting: boolean;
    userMenuStep: UserMenuStep;
    close: (e: React.SyntheticEvent<HTMLElement>) => void;
    setUserMenuStep: (userMenuStep: UserMenuStep) => void;
    onBackClick: () => void;
    hasMedia?: boolean;
    confirmationState: ConfirmationModalStates;
    setConfirmationState: (confirmationState: ConfirmationModalStates) => void;
}> = ({
    close,
    isInMeeting,
    userMenuStep,
    setUserMenuStep,
    onBackClick,
    hasMedia,
    confirmationState,
    setConfirmationState,
}) => {
    switch (userMenuStep) {
        case 'video-and-sound':
            return <VideoAndSound onBackClick={onBackClick} close={close} />;

        case 'meeting-settings':
            return <InMeetingSettings onBackClick={onBackClick} />;

        case 'about':
            return <About onBackClick={onBackClick} />;

        case 'stats':
            return <NerdStats onClose={onBackClick} />;
        case 'layouts':
            return (
                <Layouts
                    onBackClick={onBackClick}
                    close={close}
                    closeConfirmation={() =>
                        setConfirmationState(ConfirmationModalStates.None)
                    }
                    openConfirmation={() =>
                        setConfirmationState(ConfirmationModalStates.Layout)
                    }
                    isConfirmationOpen={
                        confirmationState === ConfirmationModalStates.Layout
                    }
                />
            );

        case 'quality':
            return <Quality onBackClick={onBackClick} />;

        case 'language':
            return <Languages onBackClick={onBackClick} />;

        case 'additional-settings':
            return <AdditionalSettings onBackClick={onBackClick} />;

        default:
            return (
                <UserMenuList
                    close={close}
                    isInMeeting={isInMeeting}
                    setUserMenuStep={setUserMenuStep}
                    hasMedia={hasMedia}
                />
            );
    }
};

export const UserMenuList: React.FC<{
    isInMeeting: boolean;
    setUserMenuStep: (userMenuStep: UserMenuStep) => void;
    hasMedia?: boolean;
    close: (e: React.SyntheticEvent<HTMLElement>) => void;
}> = ({isInMeeting, setUserMenuStep, hasMedia, close}) => {
    const {menuContent} = useUserMenuContent({
        isInMeeting,
        setUserMenuStep,
        hasMedia,
        close: close,
    });
    return <Menu testId={TestId.UserMenu} menuContent={menuContent} />;
};

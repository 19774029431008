import type {BrowserOptions} from '@sentry/react';

import {FRAME_RATE} from '@pexip/media-processor';
import type {MediaPriority} from '@pexip/infinity';
import {isMediaPriority} from '@pexip/infinity';
import {INCLUDE_EXCLUDE_CONSTRAINT} from '@pexip/media-control';
import type {IncludeExcludeConstraint} from '@pexip/media-control';

import type {FeedbackConfig, TestIdValue} from './types';
import {logger} from './logger';
import {shouldEnableVideoProcessing} from './config';
import {
    BANDWIDTHS,
    SEGMENTATION_MODEL,
    TERMS_URL,
    BG_IMAGE_URL,
    DEFAULT_FEEDBACK_CONFIG,
} from './constants';
import type {StringQuadruple} from './constants';

const el = document.querySelector('#ic-config');

export interface DeploymentConfig {
    sentry?: BrowserOptions;
}

export const deploymentConfig = (() => {
    try {
        return el ? (JSON.parse(el.innerHTML) as DeploymentConfig) : {};
    } catch (error: unknown) {
        logger.error(error, 'failed to parse deploymentConfig');
        return {};
    }
})();

export const applicationConfig = {
    ...deploymentConfig,
    audioProcessing: true,
    bandwidths: BANDWIDTHS,
    frameRate: FRAME_RATE,
    node: window.location.host,
    segmentationModel: SEGMENTATION_MODEL,
    showLiveCaptionsFeature: true,
    videoProcessing: shouldEnableVideoProcessing(),
    shouldMaskConference: false,
    directMedia: true,
    termsAndConditions: {
        en: TERMS_URL,
    } as Record<string, string>,
    disconnectDestination: '',
    bgImageAssets: [BG_IMAGE_URL],
    handleOauthRedirects: false,
    showTermsAndConditionsLink: true,
    monitorTypeSurfaces:
        INCLUDE_EXCLUDE_CONSTRAINT.Include as IncludeExcludeConstraint,
    selfBrowserSurface:
        INCLUDE_EXCLUDE_CONSTRAINT.Exclude as IncludeExcludeConstraint,
    systemAudio: INCLUDE_EXCLUDE_CONSTRAINT.Include as IncludeExcludeConstraint,
    hiddenFunctionality: {} as Record<TestIdValue, true>,
    enableFeedback: true,
    feedback: DEFAULT_FEEDBACK_CONFIG,
    mediaPriorities: {
        video: 'low',
        audio: 'medium',
        presentation: 'medium',
    } as MediaPriority,
};
export type ApplicationConfig = typeof applicationConfig;

export const setApplicationConfig = <K extends keyof ApplicationConfig>(
    key: K,
    value: ApplicationConfig[K],
) => {
    switch (key) {
        case 'mediaPriorities': {
            if (isMediaPriority(value)) {
                applicationConfig.mediaPriorities = value;
            }
            break;
        }
        case 'bandwidths':
            if (Array.isArray(value)) {
                const accepted = value
                    .flatMap(v => {
                        const n = +v;
                        // 0 is not a valid bandwidth
                        if (n) {
                            return [v];
                        }
                        return [];
                    })
                    .sort((a, b) => +a - +b)
                    .slice(0, BANDWIDTHS.length);
                if (accepted.length === BANDWIDTHS.length) {
                    applicationConfig.bandwidths = accepted as StringQuadruple;
                }
            }
            break;
        case 'feedback': {
            // If the feedback url is not provided, use the default one
            if ((value as FeedbackConfig).url === undefined) {
                applicationConfig.feedback.url = DEFAULT_FEEDBACK_CONFIG.url;
            } else {
                applicationConfig.feedback.url = (value as FeedbackConfig).url;
            }
            // If the enable feedback is not provided, use the default one
            if ((value as FeedbackConfig).enable === undefined) {
                applicationConfig.feedback.enable =
                    DEFAULT_FEEDBACK_CONFIG.enable;
            } else {
                applicationConfig.feedback.enable = (
                    value as FeedbackConfig
                ).enable;
            }
            break;
        }
        default:
            applicationConfig[key] = value;
            break;
    }
};

import {useCallback, useEffect, useRef, useState} from 'react';
import {useKeyboard} from '@react-aria/interactions';

import {useToggleFullscreen} from '@pexip/hooks';

import {PresentationEmphasis, StreamStatus} from '../types';
import {KEYBOARD_EVENT_KEY} from '../constants';
import type {MeetingVideoRef} from '../views';

export const useMeetingStageLayout = ({
    expandPrimaryVideo,
    isPresenting,
    isPresentationPoppedOut,
    presentationEmphasis,
    setEmphasis,
}: {
    expandPrimaryVideo: boolean;
    isPresenting: boolean;
    isPresentationPoppedOut?: boolean;
    presentationEmphasis?: PresentationEmphasis;
    setEmphasis: (emphasis: PresentationEmphasis) => void;
}) => {
    const toggleFullScreen = useToggleFullscreen();
    const [isMeetingVideoBrowserPip, setIsMeetingVideoBrowserPip] =
        useState(false);
    const [isPresentationBrowserPip, setIsPresentationBrowserPip] =
        useState(false);

    const [meetingStreamStatus, setMeetingStreamStatus] =
        useState<StreamStatus>(StreamStatus.None);
    const [presoStreamStatus, setPresoStreamStatus] = useState<StreamStatus>(
        StreamStatus.None,
    );
    const mainVideoComponentRef = useRef<MeetingVideoRef>(null);
    const presentationVideoComponentRef = useRef<MeetingVideoRef>(null);

    useEffect(() => {
        if (!isPresenting) {
            setMeetingStreamStatus(StreamStatus.Expanded);
        } else if (isPresentationPoppedOut) {
            setMeetingStreamStatus(StreamStatus.Expanded);
            setPresoStreamStatus(StreamStatus.External);
        } else if (isMeetingVideoBrowserPip) {
            setMeetingStreamStatus(StreamStatus.BrowserPip);
            setPresoStreamStatus(StreamStatus.Expanded);
        } else if (isPresentationBrowserPip) {
            setMeetingStreamStatus(StreamStatus.Expanded);
            setPresoStreamStatus(StreamStatus.BrowserPip);
        } else if (
            expandPrimaryVideo &&
            presentationEmphasis === PresentationEmphasis.Primary
        ) {
            setMeetingStreamStatus(StreamStatus.Pip);
            setPresoStreamStatus(StreamStatus.Expanded);
        } else if (
            expandPrimaryVideo &&
            presentationEmphasis === PresentationEmphasis.Secondary
        ) {
            setMeetingStreamStatus(StreamStatus.Expanded);
            setPresoStreamStatus(StreamStatus.Pip);
        } else if (
            !expandPrimaryVideo &&
            presentationEmphasis === PresentationEmphasis.Secondary
        ) {
            setMeetingStreamStatus(StreamStatus.Emphasized);
            setPresoStreamStatus(StreamStatus.Deemphasized);
        } else {
            setMeetingStreamStatus(StreamStatus.Deemphasized);
            setPresoStreamStatus(StreamStatus.Emphasized);
        }
    }, [
        isMeetingVideoBrowserPip,
        isPresentationBrowserPip,
        isPresenting,
        isPresentationPoppedOut,
        expandPrimaryVideo,
        presentationEmphasis,
    ]);

    const handleMeetingVideoClick = useCallback(async () => {
        // deemphasize a emphasized presentation on meeting click
        await handleSecondaryVideoClick(meetingStreamStatus, () => {
            setEmphasis(PresentationEmphasis.Secondary);
        });
    }, [meetingStreamStatus, setEmphasis]);

    const handlePresentationVideoClick = useCallback(async () => {
        // emphasize a deemphasized presentation on click
        await handleSecondaryVideoClick(presoStreamStatus, () => {
            setEmphasis(PresentationEmphasis.Primary);
        });
    }, [presoStreamStatus, setEmphasis]);

    const {
        keyboardProps: {onKeyDown: mainOnKeyDown},
    } = useKeyboard({
        onKeyDown: e => {
            if (e.key === KEYBOARD_EVENT_KEY.enter) {
                if (
                    meetingStreamStatus === StreamStatus.Emphasized ||
                    meetingStreamStatus === StreamStatus.Expanded
                ) {
                    toggleFullScreen();
                } else {
                    void handleMeetingVideoClick().then(() => {
                        presentationVideoComponentRef.current?.focus();
                    });
                }
            }
        },
    });

    const {
        keyboardProps: {onKeyDown: presentationOnKeyDown},
    } = useKeyboard({
        onKeyDown: e => {
            if (e.key === KEYBOARD_EVENT_KEY.enter) {
                if (
                    presoStreamStatus === StreamStatus.Emphasized ||
                    presoStreamStatus === StreamStatus.Expanded
                ) {
                    toggleFullScreen();
                } else {
                    void handlePresentationVideoClick().then(() =>
                        mainVideoComponentRef.current?.focus(),
                    );
                }
            }
        },
    });

    return {
        meetingStreamStatus,
        presoStreamStatus,
        setIsMeetingVideoBrowserPip,
        setIsPresentationBrowserPip,
        handleMeetingVideoClick,
        handlePresentationVideoClick,
        mainOnKeyDown,
        presentationOnKeyDown,
        mainVideoComponentRef,
        presentationVideoComponentRef,
    };
};

const handleSecondaryVideoClick = async (
    status: StreamStatus,
    action: () => void,
) => {
    // Secondary is defined as a meeting video with either Deemphasized or Pip status
    if (status === StreamStatus.Deemphasized || status === StreamStatus.Pip) {
        action();
        return Promise.resolve();
    }
};

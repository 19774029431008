import {useCallback, useEffect, useState} from 'react';

import type {
    useAutoHideUIInterfaces as useAutoHideUIInterfacesType,
    useFullscreenApi as useFullscreenApiType,
} from '@pexip/hooks';
import {isFullscreen} from '@pexip/hooks';
import type {Signal} from '@pexip/signal';

import type {
    EnableInMeetingUIAutoHideState,
    InMeetingUI,
    MeetingPanelsState,
    MeetingPanelType,
} from '../types';
import {PanelAnimationTypes} from '../utils/meetingPanels';

import {useInMeetingUIState} from './useInMeetingUIState';

export type InMeetingUIHookArgs = {
    enableInMeetingUIAutoHide: Signal<EnableInMeetingUIAutoHideState>;
    useAutoHideUIInterfaces: typeof useAutoHideUIInterfacesType;
    useFullscreenApi: typeof useFullscreenApiType;
    togglePanel: (
        panel: MeetingPanelType,
    ) => (panelsState: MeetingPanelsState) => [boolean, PanelAnimationTypes];
};

export const createInMeetingUIHook =
    (inMeetingUIArgs: InMeetingUIHookArgs) =>
    (isStreamReady = true): InMeetingUI => {
        const {
            enableInMeetingUIAutoHide,
            useAutoHideUIInterfaces,
            useFullscreenApi,
            togglePanel,
        } = inMeetingUIArgs;

        const [panelsState, setPanelsState] = useState<MeetingPanelsState>({
            openBreakoutRoomsPanel: false,
            openChatPanel: false,
            openParticipantPanel: false,
            openAddParticipant: false,
            openPluginsPanel: false,
            animationType: PanelAnimationTypes.NONE,
        });

        const togglePanels = useCallback(
            (panel: MeetingPanelType) => {
                if (panel === 'add') {
                    setPanelsState(prevPanelsState => ({
                        ...prevPanelsState,
                        openAddParticipant: !prevPanelsState.openAddParticipant,
                    }));
                    return;
                }

                setPanelsState(prevPanelsState => {
                    const [isOpen, animationType] =
                        togglePanel(panel)(prevPanelsState);

                    const isChatToShow = panel === 'chat' && isOpen;
                    const isParticipantsToShow =
                        panel === 'participants' && isOpen;
                    const isBreakoutRoomsToShow =
                        panel === 'breakoutRooms' && isOpen;
                    const isPluginsToShow = panel === 'plugins' && isOpen;

                    return {
                        openAddParticipant: prevPanelsState.openAddParticipant,
                        openBreakoutRoomsPanel: isBreakoutRoomsToShow,
                        openChatPanel: isChatToShow,
                        openParticipantPanel: isParticipantsToShow,
                        openPluginsPanel: isPluginsToShow,
                        animationType: animationType,
                    };
                });
            },
            [togglePanel],
        );

        const {enableAutoHide} = useAutoHideUIInterfaces(isStreamReady);

        const showSidePanel =
            panelsState.openParticipantPanel ||
            panelsState.openChatPanel ||
            panelsState.openBreakoutRoomsPanel ||
            panelsState.openPluginsPanel;

        const {autoHideProps, isAutoHideUIVisible} = useInMeetingUIState(
            showSidePanel,
            enableAutoHide,
            enableInMeetingUIAutoHide,
        );
        const fullscreenApi = useFullscreenApi();

        useEffect(
            () => () => {
                if (isFullscreen(fullscreenApi.fullscreenElement)) {
                    void fullscreenApi.exitFullscreen?.();
                }
            },
            [fullscreenApi],
        );

        return {
            autoHideProps,
            panelsState,
            showSidePanel,
            togglePanels,
            isAutoHideUIVisible,
        };
    };

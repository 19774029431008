import {
    subscribe,
    MediaEventType,
    MediaDeviceFailure,
    createStreamTrackEventSubscriptions,
    getDevices,
    isRequestedResolution,
    mergeConstraints,
    shouldRequestDevice,
} from '@pexip/media-control';
import type {
    MediaDeviceRequest,
    MediaDeviceInfoLike,
} from '@pexip/media-control';
import {createAsyncQueue, isEmpty} from '@pexip/utils';

import type {
    MediaOptions,
    MediaProps,
    MediaSignals,
    MediaController,
    Media,
} from './types';
import {
    getPermissionStatus,
    deriveInitialPermissionStatus,
    isInitialPermissions,
    isInitialPermissionsGranted,
} from './status';
import {UserMediaStatus} from './types';
import {createModuleLogger, logger} from './logger';
import {
    createGetUserMediaProcess,
    requestUserMediaWithRetry,
} from './userMedia';
import {
    createMediaPipeline,
    createMediaProcess,
    buildMedia,
    getDevicesChanges,
    shallowCopy,
    wrapToJSON,
    hasSettingsChanged,
    AUDIO_SETTINGS_KEYS,
    VIDEO_SETTINGS_KEYS,
    MIXING_SETTINGS_KEYS,
    applyContentHint,
} from './utils';
import {isMedia} from './typeGuard';
import {updateFeatureProps as getVideoFeatures} from './videoProcessor';
import {updateFeatureProps as getAudioFeatures} from './audioProcessor';
import {updateFeatureProps as getMixingFeatures} from './audioMixingProcessor';

/**
 * Proxy handler for Media Props
 */
const createMediaPropsHandler = (
    signals?: MediaSignals,
): ProxyHandler<MediaProps> => ({
    get: (target, p: keyof MediaProps) => {
        switch (p) {
            default:
                return target[p];
        }
    },
    set: (target, p: keyof MediaProps, value) => {
        if (target[p] === value) {
            return true;
        }
        if (p === 'devices') {
            const nextDevices = value as MediaDeviceInfo[];
            const changes = getDevicesChanges(
                target[p].flatMap(device => (device.label ? [device] : [])),
                nextDevices,
            );
            if (isEmpty(changes.found) && isEmpty(changes.lost)) {
                return true;
            }
        }
        logger.debug(
            {
                oldValue: target[p],
                newValue: value as unknown,
                meta: {
                    module: 'Media',
                    props: target,
                },
            },
            `Update Props[${p}]`,
        );
        switch (p) {
            case 'devices': {
                if (!Array.isArray(value)) {
                    return false;
                }
                const devices = value as MediaDeviceInfo[];
                target[p] = devices;
                signals?.onDevicesChanged?.emit(devices);
                target.media.devices = devices;
                return true;
            }
            case 'media': {
                if (!isMedia(value)) {
                    return false;
                }

                const currentStatus = target[p].status;
                target[p] = value;
                signals?.onMediaChanged?.emit(value);
                if (currentStatus !== value.status) {
                    signals?.onStatusChanged?.emit(value.status);
                }

                return true;
            }
            default: {
                Reflect.set(target, p, value);
                return true;
            }
        }
    },
});

/**
 * Create an object to interact with the media scream, which is usually used for
 * our main stream.
 *
 * @param options - @see MediaOptions
 */
export const createMedia = ({
    getMuteState,
    signals,
    mediaProcessors,
    getDefaultConstraints = () => ({}),
}: MediaOptions): MediaController => {
    const initMedia = (
        status = UserMediaStatus.Initial,
        devices: MediaProps['devices'] = [],
        constraints?: MediaDeviceRequest,
    ) =>
        buildMedia(
            () => ({status, devices, constraints}),
            signals.onStatusChanged?.emit,
        );
    const _props: MediaProps = {
        devices: [],
        media: initMedia(),
        discardMedia: false,
    };
    const props = new Proxy(_props, createMediaPropsHandler(signals));
    const queue = createAsyncQueue();

    const logger = createModuleLogger({
        module: 'Media',
        props: _props,
        get mediaTracks() {
            return _props.media?.stream?.getTracks();
        },
        get rawMediaTracks() {
            return _props.media?.rawStream?.getTracks();
        },
    });

    const cleanup = async () => {
        if (isInitialPermissions(props.media.status)) {
            props.discardMedia = true;
        }

        // carry the most recent status over for the next time
        const status = await deriveInitialPermissionStatus(props.media.status);
        props.media = initMedia(status, props.devices, props.media.constraints);
    };

    // Media Pipeline
    const mediaPipeline = createMediaPipeline([
        createGetUserMediaProcess(
            requestUserMediaWithRetry(),
            () => props.devices,
            {initialMedia: props.media},
        ),
        ...mediaProcessors,
        createMediaProcess(media => {
            // Subscribe the track event from raw stream
            const trackSubscriptions = media.rawStream?.getTracks().map(track =>
                createStreamTrackEventSubscriptions(track, {
                    ended: signals.onStreamTrackEnded?.emit,
                    mute: signals.onStreamTrackMuted?.emit,
                    unmute: signals.onStreamTrackUnmuted?.emit,
                }),
            );

            const muteTrack =
                (tracks: MediaStreamTrack[]) => (muted: boolean) => {
                    const [track] = tracks;
                    const kind = track?.kind;
                    if (track && (kind === 'audio' || kind === 'video')) {
                        media[kind === 'audio' ? 'muteAudio' : 'muteVideo'](
                            muted,
                        );
                        return tracks.forEach(track => {
                            logger.debug(
                                {trackInResult: track, intendToMute: muted},
                                `mute ${track.kind}`,
                            );
                            signals.onStreamTrackEnabled?.emit(track);
                        });
                    }
                    logger.warn({tracks, kind}, 'trying to mute but no track');
                };

            const muteAudio = muteTrack(media.stream?.getAudioTracks() ?? []);
            const muteVideo = muteTrack(media.stream?.getVideoTracks() ?? []);

            // Sync mute
            const {audio, video} = getMuteState();
            media.audioMuted !== undefined && muteAudio(audio);
            media.videoMuted !== undefined && muteVideo(video);

            const release = async () => {
                trackSubscriptions?.forEach(unsubscribe => unsubscribe());
                await media.release();
                await cleanup();
            };

            const applyConstraints: Media['applyConstraints'] =
                async constraints => {
                    await media.applyConstraints(constraints);
                    const videoFeatures = getVideoFeatures(
                        constraints.video,
                        {},
                    );
                    const audioFeatures = getAudioFeatures(
                        constraints.audio,
                        {},
                    );
                    media.stream
                        ?.getAudioTracks()
                        .forEach(applyContentHint(audioFeatures.contentHint));
                    media.stream
                        ?.getVideoTracks()
                        .forEach(applyContentHint(videoFeatures.contentHint));
                };

            logger.debug(
                {finalAudioMute: audio, finalVideoMute: video},
                'End of media pipeline',
            );
            return wrapToJSON(
                shallowCopy(media, {
                    release,
                    muteAudio: mute => {
                        muteAudio(mute);
                        signals.onAudioMuteStateChanged?.emit(mute);
                    },
                    muteVideo: mute => {
                        muteVideo(mute);
                        signals.onVideoMuteStateChanged?.emit(mute);
                    },
                    applyConstraints,
                }),
            );
        }),
    ]);

    /**
     * A function to update the media only when necessary, aka the current setup
     * for the stream is different from the new constraints
     *
     * @param constraints - @see MediaDeviceRequest
     */
    const updateMedia = async (constraints: MediaDeviceRequest) => {
        if (!constraints.audio && !constraints.video) {
            throw new Error(MediaDeviceFailure.MissingConstraintsError);
        }

        const shouldRequestAudio = shouldRequestDevice(
            constraints.audio,
            props.media?.rawStream?.getAudioTracks() ?? [],
            props.devices.filter(device => device.kind === 'audioinput'),
        );
        const shouldRequestVideo = shouldRequestDevice(
            constraints.video,
            props.media?.rawStream?.getVideoTracks() ?? [],
            props.devices.filter(device => device.kind === 'videoinput'),
        );

        const {
            audio: [prevAudioSettings],
            video: [prevVideoSettings],
        } = props.media.getSettings();

        const videoFeatures = getVideoFeatures(constraints.video, {});
        const audioFeatures = getAudioFeatures(constraints.audio, {});
        const mixingFeatures = getMixingFeatures(constraints.audio, {});

        const hasRequestedResolution =
            ['blur', 'overlay'].includes(
                videoFeatures.videoSegmentation ?? '',
            ) || // Skip when there is a render effect to modify the video
            !props.devices.some(
                device => device.kind === 'videoinput' && device.label,
            ) || // Skip when no authorized video found
            isRequestedResolution(constraints.video, props.media?.videoInput);

        const audioFeaturesChanged = hasSettingsChanged(AUDIO_SETTINGS_KEYS)(
            prevAudioSettings,
            audioFeatures,
        );
        const videoFeaturesChanged = hasSettingsChanged(VIDEO_SETTINGS_KEYS)(
            prevVideoSettings,
            videoFeatures,
        );
        const mixingFeaturesChanged = hasSettingsChanged(MIXING_SETTINGS_KEYS)(
            prevAudioSettings,
            mixingFeatures,
        );
        const ptzFeaturesChanges = hasSettingsChanged(['pan', 'tilt', 'zoom'])(
            prevVideoSettings,
            videoFeatures,
        );

        logger.debug(
            {
                constraints,
                stream: props.media.stream,
                currentDevices: props.devices,
                shouldRequestAudio,
                shouldRequestVideo,
                sameResolution: hasRequestedResolution,
                audioFeaturesChanged,
                videoFeaturesChanged,
                mixingFeaturesChanged,
            },
            'Is currently streaming requested stream',
        );
        if (
            shouldRequestAudio ||
            shouldRequestVideo ||
            ptzFeaturesChanges ||
            !hasRequestedResolution ||
            !props.media.stream
        ) {
            if (props.media) {
                await props.media.release();
            }
            if (props.discardMedia) {
                props.discardMedia = false;
            }
            const media = await mediaPipeline.execute(constraints);
            media.stream
                ?.getAudioTracks()
                .forEach(applyContentHint(audioFeatures.contentHint));
            media.stream
                ?.getVideoTracks()
                .forEach(applyContentHint(videoFeatures.contentHint));
            if (props.discardMedia) {
                logger.debug('Discard media');
                return await media.release();
            }
            props.media = media;
        } else if (
            audioFeaturesChanged ||
            videoFeaturesChanged ||
            mixingFeaturesChanged
        ) {
            props.media.constraints = constraints;
            await props.media.applyConstraints({
                audio: audioFeatures,
                video: videoFeatures,
            });
        }
    };

    const mergeMediaConstraints = (constraints: MediaDeviceRequest) => {
        const {audio, video} = getDefaultConstraints();
        return {
            audio:
                audio === false
                    ? false
                    : mergeConstraints(audio)(constraints.audio),
            video:
                video === false
                    ? false
                    : mergeConstraints(video)(constraints.video),
        };
    };

    const getUserMediaAsync: MediaController['getUserMediaAsync'] =
        async constraints => {
            queue.enqueue(
                async () =>
                    await updateMedia(mergeMediaConstraints(constraints)),
                false,
            );
            await queue.execute();
        };

    const getUserMedia: MediaController['getUserMedia'] = constraints => {
        queue.enqueue(
            async () => await updateMedia(mergeMediaConstraints(constraints)),
        );
    };

    const tryAndGetUserMedia: MediaController['tryAndGetUserMedia'] =
        constraints => {
            queue.enqueue(async () => {
                props.media.status = await getPermissionStatus();
                logger.debug(
                    {status: props.media.status},
                    'Current Permission State',
                );
                const mergedConstraints = mergeMediaConstraints(constraints);
                props.media.constraints = mergedConstraints;
                props.devices = await getDevices();
                if (!mergedConstraints.audio && !mergedConstraints.video) {
                    return;
                }
                if (isInitialPermissionsGranted(props.media.status)) {
                    return await updateMedia(mergedConstraints);
                }
            });
        };

    // Media Control Event handlers
    const handleNoInputs = (_availableDevices: MediaDeviceInfoLike[]) => {
        props.media.status = UserMediaStatus.NoDevicesFound;
    };

    const handleDeviceChange = (devices: MediaDeviceInfoLike[]) => {
        props.devices = devices;
    };

    // Subscribe Media Events for devices
    subscribe(event => {
        switch (event.detail.type) {
            case MediaEventType.NoInputDevices:
                logger.debug(event.detail, 'NoInputDevices emitted');
                handleNoInputs(event.detail.devices);
                break;

            case MediaEventType.DevicesFound:
            case MediaEventType.DevicesLost:
                logger.debug(event.detail, 'DevicesFound/Lost emitted');
                handleDeviceChange([
                    ...event.detail.authorizedDevices,
                    ...event.detail.unauthorizedDevices,
                ]);
                break;
            default:
                break;
        }
    });

    return {
        get media() {
            return props.media;
        },

        get devices() {
            return props.devices;
        },

        getUserMedia,
        getUserMediaAsync,
        tryAndGetUserMedia,
    };
};
